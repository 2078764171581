import React from "react";
import { Link } from "gatsby";

import Button from "../Button/Button";
import sortAktivitaetskategories from "../../util/sortAktivitaetskategories";

function PreviewAktivitaetPublikation(props) {
  let {
    slug,
    titel,
    aktivitaetskategories,
    imErscheinen,
    kurzbeschreibung,
    datum,
    excerptLength,
    autoren,
    ...rest
  } = props;

  let shortenedKurzbeschreibung = (text) => {
    if (!text) return;
    let maxLength = excerptLength ? excerptLength : 150;
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " [...]";
    } else {
      return text;
    }
  };

  let datumformatiert = (datum) => {
    let dateObject = new Date(datum);
    return dateObject.getFullYear();
  };

  let datumsanzeige = (datum, imErscheinen) => {
    if (imErscheinen === true) {
      return " [im Erscheinen]";
    } else {
      if (datum) {
        return ` (${datumformatiert(datum)})`;
      } else {
        return null;
      }
    }
  };

  return (
    <Link to={`/${slug}`} {...props}>
      <div key={slug} className="shadow-lg p-6 h-full">
        {/**<p className="opacity-75 mb-2">
          {aktivitaetskategories
            ? sortAktivitaetskategories(aktivitaetskategories)
                .map((cat) => {
                  return String(cat.Titel);
                })
                .join(" | ")
            : ""}
        </p>*/}
        <h1 className="text-md text-black font-bold mb-2 leading-tight">
          {titel}
        </h1>

        {
          <p className="mb-2">
            {autoren &&
              autoren
                .map((autor) => {
                  return `${autor.Nachname}, ${autor.Vorname}`;
                })
                .join("; ")}
            {datumsanzeige(datum, imErscheinen)}
          </p>
        }

        <p className="leading-tight">
          {shortenedKurzbeschreibung(kurzbeschreibung)}
        </p>
        <Button className="mt-4">ansehen</Button>
      </div>
    </Link>
  );
}

export default PreviewAktivitaetPublikation;
