import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentFrame from "../components/ContentFrame";
import Button from "../components/Button/Button";
import BlockTextImage from "../components/BlockTextImage";
import MeldungPreview from "../components/MeldungPreview";
import ProfilfelderOverview from "../components/ProfilfelderOverview";

import SaeuleContentForschung from "../components/SaeuleContent/SaeuleContentForschung";
import SaeuleContentLehre from "../components/SaeuleContent/SaeuleContentLehre";
import SaeuleContentTransfer from "../components/SaeuleContent/SaeuleContentTransfer";

import * as styles from "./saeuleTemplate.module.scss";
import ProfilfeldLinks from "../components/ProfilfeldLinks";

const sections = {
  forschung: [
    { name: "Monografien.", slug: "monografie" },
    { name: "Sammelbände.", slug: "sammelband" },
    {
      name: "Buch- und Zeitschriften&shy;reihen.",
      slug: "buch-und-zeitschriftenreihe",
    },
    { name: "Zeitschriften.", slug: "zeitschrift" },
    {
      name: "Artikel.",
      slug: "artikel",
      text: "Auswahl unserer publizierten Artikel.",
    },
    {
      name: "Artikel in Fach&shy;zeit&shy;schriften (referiert).",
      slug: "artikel-in-fachzeitschrift-referiert",
      text: "",
    },
    {
      name: "Artikel in Lexika und Handbüchern.",
      slug: "artikel-in-lexika-und-handbuechern",
    },
    {
      name: "Artikel in Zeitschriften (nicht-referiert).",
      slug: "artikel-in-zeitschriften-nicht-referiert",
    },
  ],
  lehre: [
    {
      name: "Vorlesungen.",
      slug: "vorlesung",
      text: "Diesjährige Vorlesungen.",
    },
    {
      name: "Seminare.",
      slug: "seminar",
      text: "Diesjährige Seminare.",
    },
    {
      name: "Qualifikations&shy;arbeiten.",
      slug: "qualifikationsarbeit",
      text: `Hinweise für Kooperationspartner von Bachelor- und Masterarbeiten finden Sie <a class="underline" href="/informationen-zu-qualifikationsarbeiten-fuer-kooperationspartner">hier</a>.`,
    },
    {
      name: "Praktikums&shy;erfahrungen.",
      slug: "praktikum",
      text: `
      <p>Informationen zu Praktika <a href="/informationen-zu-praktika
      " class="underline" >hier</a>.</p>`,
    },
    {
      name: "Hinweise für Studierende.",
      slug: "hinweise-fuer-studierende",
    },
  ],
  transfer: [
    {
      name: "Master-, MBA- und Promotions&shy;programme.",
      slug: "master-mba-und-promotionsprogramme",
      text: "Mit P&E akademisch Karriere machen!",
    },
    {
      name: "Unter&shy;nehmens&shy;gründungen.",
      slug: "unternehmensgruendung",
      text: "Die Welt verbessern mit P&E Gründungen!",
    },
  ],
};

let addedLinksOftransfer;

function Saeule({ data }) {
  const kompetenzfeld = data.strapiKompetenzfeld;
  const aktivitaets = data.allStrapiAktivitaet;
  const saeule = data.strapiSaeule;
  const meldungen = data.allWpPost;

  let bgClass = "bg-primary";

  if (saeule.Slug === "transfer") {
    bgClass = "bg-tertiary";
  }

  if (saeule.Slug === "lehre") {
    bgClass = "bg-secondary";
  }

  return (
    <Layout>
      <SEO
        keywords={[
          `Institut`,
          `Ethik`,
          `Management`,
          `Bayreuth`,
          `${saeule.Titel}`,
        ]}
        title={saeule.Titel}
      />
      <section id="hero" className="mb-6">
        <div className={`py-12`}>
          <div className="container mx-auto grid lg:grid-cols-2 gap-4">
            <div
              className={`${bgClass} text-white sm:p-8 p-6 text-left font-light`}
            >
              <p>InnovationLab Ethik und Management</p>
              <h1 className="font-header mt-4 text-3xl sm:text-4xl">
                {saeule.Titel}.
              </h1>
              <ProfilfeldLinks
                edges={data.allStrapiSaeule.edges}
                isWhite={true}
              />
              <div className="width-full font-light mt-8 text-md md:pr-6">
                <ReactMarkdown className={`${styles.kurzbeschreibung}`}>
                  {saeule.Kurzbeschreibung}
                </ReactMarkdown>
              </div>
            </div>
            <div className="lg:mt-0 mt-6">
              <nav className="flex flex-col h-full md:w-4/5 md:pl-10 justify-center items-start text-center md:text-left my-auto">
                <ul className="mx-auto font-light text-lg space-y-3 ">
                  {saeule.Slug === "forschung" &&
                    sections.forschung.map((link) => (
                      <li>
                        <Button to={`#${link.slug}`} isAnchor={true}>
                          {link.name}
                        </Button>
                      </li>
                    ))}
                  {saeule.Slug === "lehre" &&
                    sections.lehre.map((link) => (
                      <li>
                        <Button to={`#${link.slug}`} isAnchor={true}>
                          {link.name}
                        </Button>
                      </li>
                    ))}
                  {saeule.Slug === "transfer" && (
                    <>
                      <li>
                        <Button to={`/concern`}>Concern GmbH.</Button>
                      </li>
                      <li>
                        <Button to={`/bayreuther-dialoge`}>
                          bayreuther dialoge.
                        </Button>
                      </li>
                      <li>
                        <Button to={`/think-tank`}>Think Tank.</Button>
                      </li>
                      <li>
                        <Button to="/sustainability-management-zertifikatskurs">
                        Intensivkurs Sustainability Management.
                        </Button>
                      </li>
                      {sections.transfer.map((link) => (
                        <li>
                          <Button
                            to={`/${saeule.Slug}#${link.slug}`}
                            isAnchor={true}
                          >
                            {link.name}
                          </Button>
                        </li>
                      ))}
                        <li>
                        <Button to="/informationen-zu-qualifikationsarbeiten-fuer-kooperationspartner">
                        Kooperationspartner.
                        </Button>
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {saeule.Slug === "forschung" && (
        <SaeuleContentForschung
          {...saeule}
          aktivitaets={aktivitaets}
          sections={sections.forschung}
        />
      )}
      {saeule.Slug === "lehre" && (
        <SaeuleContentLehre
          {...saeule}
          aktivitaets={aktivitaets}
          sections={sections.lehre}
        />
      )}
      {saeule.Slug === "transfer" && (
        <SaeuleContentTransfer
          {...saeule}
          aktivitaets={aktivitaets}
          sections={sections.transfer}
        />
      )}

      <ProfilfelderOverview bgClass={bgClass} />
      <div className="mb-10"></div>

      <section id="Aktuelles">
        <div className="container my-auto py-10 grid lg:grid-cols-3 md:grid-cols-2 gap-8">
          <div className="">
            <h2 className="font-header font-light text-4xl">Aktuelles.</h2>
            <p className="font-light text-lg leading-loose">
              Aktuelle Meldungen zur {saeule.Titel} am i-em.
            </p>
            <Button to="/aktuelles" className="font-xl mt-4">
              alle Meldungen
            </Button>
          </div>
          {meldungen.edges.map(({ node }) => (
            <MeldungPreview node={node} />
          ))}
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!, $camel: String!) {
    allStrapiSaeule(filter: { Slug: { ne: $slug } }) {
      edges {
        node {
          Titel
          Slug
        }
      }
    }

    strapiSaeule(Slug: { eq: $slug }) {
      Slug
      Titel
      Kurzbeschreibung
      PublikationsListePDF {
        url
      }
    }

    allStrapiAktivitaet(
      filter: { saules: { elemMatch: { Slug: { eq: $slug } } } }
    ) {
      nodes {
        Titel
        Slug
        Kurzbeschreibung
        imErscheinen
        Datum(formatString: "Y")
        aktivitatskategories {
          Titel
          Slug
        }
        people {
          Vorname
          Nachname
        }
      }
    }

    allWpPost(limit: 8, filter: { strapiConnect: { saeule: { eq: $camel } } }) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "DD.MM.YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Saeule;
