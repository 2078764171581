import React from "react";
import Img from "gatsby-image";

import PreviewAktivitaetPublikation from "../../components/PreviewAktivitaet/PreviewAktivitaetPublikation";
import PreviewAktivitaet from "../../components/PreviewAktivitaet";
import Button from "../../components/Button/Button";

function SaeuleContentLehre(props) {
  return (
    <div className="container mx-auto">
      <section id="Publikationen">
        {props.sections.map((type) => (
          <div>
            <div
              id={`${type.slug}`}
              className="grid md:grid-cols-4 lg:grid-cols-6 gap-6 mb-8 auto-rows-auto"
            >
              <div className="col-span-2 row-span-1 p-6 bg-secondary text-white font-light flex flex-col">
                <h2
                  className="font-header break-words text-2xl mb-4"
                  dangerouslySetInnerHTML={{ __html: type.name && type.name }}
                ></h2>
                <p
                  className="text-xl"
                  dangerouslySetInnerHTML={{ __html: type.text && type.text }}
                ></p>
              </div>
              {props.aktivitaets.nodes.map((aktivitaet) => {
                if (
                  aktivitaet.aktivitatskategories.some(
                    (key) => key["Slug"] === type.slug
                  )
                ) {
                  return (
                    <PreviewAktivitaetPublikation
                      className="col-span-2"
                      data-sal="slide-up"
                      data-sal-delay={`200`}
                      data-sal-easing="ease-in-out"
                      data-sal-duration="5000"
                      datum={aktivitaet.Datum}
                      imErscheinen={aktivitaet.imErscheinen}
                      slug={aktivitaet.Slug}
                      titel={aktivitaet.Titel}
                      kurzbeschreibung={aktivitaet.Kurzbeschreibung}
                      aktivitaetskategories={aktivitaet.aktivitatskategories}
                      autoren={aktivitaet.people}
                    />
                  );
                }
              })}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default SaeuleContentLehre;
