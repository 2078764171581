import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import PreviewAktivitaetPublikation from "../../components/PreviewAktivitaet/PreviewAktivitaetPublikation";
import Button from "../../components/Button/Button";

import * as styles from "./saeule.module.scss";

function SaeuleContentTransfer(props) {
  return (
    <StaticQuery
      query={graphql`
        query TransferQuery {
          BlueOcean: allFile(
            filter: { relativePath: { eq: "blue-ocean.jpg" } }
          ) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          Hand: allFile(filter: { relativePath: { eq: "hand.jpg" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          Dialoge: allFile(filter: { relativePath: { eq: "dialoge.jpg" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }

          Featured: allStrapiAktivitaet(
            filter: { inVorschauSaeule: { eq: true } }
          ) {
            edges {
              node {
                id
                Aktivitaetslink
                Titel
                Slug
                VorschautextSaeule
                VorschaubildSaeule {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                Content
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <div className="font-light">
            <div className="container mx-auto grid lg:grid-cols-2 md:grid-cols-2">
              {data.Featured.edges.map(({ node }) => (
                <section
                  id={node.Slug}
                  className={`${styles.card} py-10 md:px-6 bg-primary text-white relative overflow-hidden md:m-6 m-2 hover:shadow-lg text-center md:text-left`}
                  key={node.id}
                >
                  <div className="relative z-10">
                    <div className="pb-2">
                      <h2
                        className={`font-header text-2xl `}
                        dangerouslySetInnerHTML={{
                          __html: `<span>${node.Titel}.<span>`,
                        }}
                      ></h2>
                      <p
                        className="mb-4 text-xl"
                        dangerouslySetInnerHTML={{
                          __html: String(node.VorschautextSaeule),
                        }}
                      ></p>
                      <Button to={`/${node.Slug}`}>mehr</Button>
                    </div>
                  </div>
                 



                 
                </section>
              ))}
            </div>

            <div className="container mx-auto mt-10">
              <section>
                {props.sections.map((type) => (
                  <div id={type.slug}>
                    <div
                      id={`${type.name}`}
                      className="grid md:grid-cols-4 lg:grid-cols-6 gap-6 mb-8 auto-rows-auto"
                    >
                      <div className="col-span-2 row-span-1 p-6 bg-tertiary text-white font-light flex flex-col">
                        <h2
                          className="font-header text-2xl mb-4"
                          dangerouslySetInnerHTML={{ __html: type.name }}
                        ></h2>
                        <p
                          className="text-xl"
                          dangerouslySetInnerHTML={{
                            __html: type.text && type.text,
                          }}
                        ></p>
                      </div>
                      {props.aktivitaets.nodes.map((aktivitaet) => {
                        if (
                          aktivitaet.aktivitatskategories.some(
                            (key) => key["Slug"] === type.slug
                          )
                        ) {
                          return (
                            <PreviewAktivitaetPublikation
                              className="col-span-2"
                              data-sal="slide-up"
                              data-sal-delay={`200`}
                              data-sal-easing="ease-in-out"
                              data-sal-duration="5000"
                              datum={aktivitaet.Datum}
                              imErscheinen={aktivitaet.imErscheinen}
                              slug={aktivitaet.Slug}
                              titel={aktivitaet.Titel}
                              kurzbeschreibung={aktivitaet.Kurzbeschreibung}
                              aktivitaetskategories={
                                aktivitaet.aktivitatskategories
                              }
                              autoren={aktivitaet.people}
                            />
                          );
                        }
                      })}
                    </div>
                  </div>
                ))}
              </section>
            </div>
          </div>
        );
      }}
    />
  );
}

export default SaeuleContentTransfer;
