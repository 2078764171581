import React from "react";
import { Link } from "gatsby";

import * as styles from "./ProfilfeldLinks.module.scss";

const ProfilfeldLinks = ({ edges, seperator = " | ", isWhite, className }) => {
  return (
    <div
      className={`${
        isWhite == true ? styles.underlineLinksWhite : styles.underlineLinks
      } ${className}`}
    >
      {edges.length > 0 &&
        edges
          .map((profilfeld) => (
            <Link
              className={` hover:opacity-100`}
              to={`/${
                profilfeld.node ? profilfeld.node.Slug : profilfeld.Slug
              }`}
            >
              {profilfeld.node ? profilfeld.node.Titel : profilfeld.Titel}
            </Link>
          ))
          .reduce((prev, curr) => [prev, seperator, curr])}
    </div>
  );
};

export default ProfilfeldLinks;
