import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const MeldungPreview = ({ node }) => {
  return (
    <Link to={`/meldung/${node.slug}`}>
      <div>
        {node.featuredImage ? (
          <Img
            style={{ height: 200 }}
            imgStyle={{ objectFit: "cover" }}
            fluid={node.featuredImage?.node?.localFile?.childImageSharp?.fluid}
            className="mb-6"
          />
        ) : (
          <div
            style={{ height: 200 }}
            className="bg-primary opacity-25 mb-6"
          ></div>
        )}
        <p>{node.date}</p>
        <h3
          style={{ minHeight: "5.5em" }}
          className="font-header break-words text-xl mt-4"
        >
          {node.title}
        </h3>
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: node.excerpt }}
        ></div>
      </div>
    </Link>
  );
};

export default MeldungPreview;
