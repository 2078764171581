let sortAktivitaetskategories = (kats) => {
  if (!kats) return;

  /**
   * Put publication to front
   */
  if (kats.some((key) => key["Titel"] === "Publikation")) {
    let filteredKats = kats.filter((el) => el.Titel !== "Publikation");
    filteredKats.unshift({ Titel: "Publikation" });
    return filteredKats;
  }

  /**
   * TODO: Put Lehrveranstaltung to front
   */

  return kats;
};

export default sortAktivitaetskategories;
