import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useState } from "react";

import * as styles from "../layout.module.scss";

const ProfilfelderOverview = ({ bgClass }) => {
  const [bgImage, changeBgImage] = useState(null);
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiKompetenzfeld(sort: { fields: Titel }) {
            edges {
              node {
                Titel
                Slug
                Kurzbeschreibung
                Titelbild {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <section
          id="profilfilder"
          className={`${
            bgClass ? bgClass : "bg-primary"
          } text-white relative overflow-hidden`}
        >
          <div className="container relative z-10  mx-auto grid md:grid-cols-5 gap-6 py-12">
            <div className="xl:col-span-2 col-span-5">
              <h2 className="font-header font-light text-4xl">Profilfelder.</h2>
              <p
                className={`${styles.underlineLinks} font-light text-lg leading-loose`}
              >
                Themenfelder der Zukunft, die uns auszeichnen und uns
                beschäftigen - in <Link to="/forschung">Forschung</Link>,{" "}
                <Link to="/lehre">Lehre</Link> und{" "}
                <Link to="/transfer">Transfer</Link>.
              </p>
            </div>
            <div
              className={`${styles.underlineLinksProfilfelder} xl:col-span-3 col-span-5 grid md:grid-cols-3 grid-cols-2 gap-4 font-header text-lg font-light`}
            >
              {data.allStrapiKompetenzfeld.edges.map(({ node }) => (
                <Link
                  className="relative z-10 bg-white text-primary hover:text-white hover:bg-transparent text-center py-7 px-2 flex flex-col justify-center transition duration-500 ease-in-out"
                  to={`/${node.Slug}`}
                  key={node.Slug}
                  onMouseOver={() =>
                    changeBgImage(
                      node.Titelbild.localFile.childImageSharp.fluid
                    )
                  }
                >
                  <p className="relative break-words z-10">{node.Titel}</p>
                </Link>
              ))}
            </div>
          </div>
          <div className="absolute opacity-25 top-0 bottom-0 right-0 left-0 z-0 overflow-hidden">
            {bgImage && (
              <Img
                className="h-full"
                imgStyle={{ objectFit: "cover" }}
                fluid={bgImage}
              />
            )}
          </div>
        </section>
      )}
    />
  );
};

export default ProfilfelderOverview;
