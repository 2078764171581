import React from "react";
import StaticImage from "gatsby-image";
import ReactMarkdown from "react-markdown";

import * as styles from "./BlockTextImage.module.scss";

function BlockTextImage({ BildLinks, Content, Heading, Image }) {
  return (
    <div
      className={`${styles.underlineLinks} underline-links ${
        Image ? "sm:grid" : "text-center block md:w-1/2 mx-auto"
      }  text-gray-700 mb-12 leading-8 grid-flow-col-dense md:grid-cols-2 md:gap-10`}
    >
      <div
        style={{ gridColumn: BildLinks ? "2" : "1" }}
        data-sal="slide-up"
        data-sal-delay={`200`}
        data-sal-easing="ease-in-out"
        data-sal-duration="5000"
        className="mb-4 flex flex-col justify-center"
      >
        <h2 className="font-header leading-relaxed mb-6 text-2xl">{Heading}</h2>
        <ReactMarkdown className="prose mx-auto">{Content}</ReactMarkdown>
      </div>
      {Image && (
        <div
          style={{
            gridColumn: BildLinks ? "1" : "2",
            minWidth: "300px",
          }}
          data-sal="slide-up"
          data-sal-delay={`400`}
          data-sal-easing="ease-in-out"
          data-sal-duration="5000"
          className="w-full flex flex-col justify-center relative"
        >
          <div className="bg-primary">
            <img
              style={{ minHeight: "300px" }}
              src={
                Image.formats.medium
                  ? Image.formats.medium.url
                  : Image.formats.small.url
              }
              className="opacity-75"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BlockTextImage;
