import React from "react";
import { Link } from "gatsby";

import Button from "../Button/Button";
import sortAktivitaetskategories from "../../util/sortAktivitaetskategories";

function PreviewAktivitaet(props) {
  let { slug, titel, aktivitaetskategories, kurzbeschreibung } = props;
  let shortenedKurzbeschreibung = (text) => {
    if (!text) return;
    let maxLength = 255;
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " [...]";
    } else {
      return text;
    }
  };

  return (
    <Link to={`/${slug}`} {...props}>
      <div key={slug} className="shadow-lg p-6 h-full">
        <p className="opacity-75 mb-2">
          {aktivitaetskategories
            ? sortAktivitaetskategories(aktivitaetskategories)
                .map((cat) => {
                  return String(cat.Titel);
                })
                .join(" | ")
            : ""}
        </p>
        <h1 className="text-xl text-black font-bold mb-2 leading-tight">
          {titel}
        </h1>
        <p className="leading-tight">
          {shortenedKurzbeschreibung(kurzbeschreibung)}
        </p>
        <Button className="mt-4">ansehen</Button>
      </div>
    </Link>
  );
}

export default PreviewAktivitaet;
